import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { AlertType, alertActions } from 'entities/Alert';
import {
  NewPaymentGatewayWithdrawalError,
  NewPaymentGatewayWithdrawalResponse
} from '../types/paymentWithdrawal/paymentWithdrawal';
import { getDataToCreateNewPaymentGatewayWithdrawal } from '../selectors/newPaymentGatewayWithdrawal';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const createNewPaymentGatewayWithdrawal = createAsyncThunk<
NewPaymentGatewayWithdrawalResponse,
undefined,
ThunkConfig<MyAxiosError<NewPaymentGatewayWithdrawalError>>
>(
  'newPaymentGatewayWithdrawal/createNewPaymentGatewayWithdrawal',
  async (_, { rejectWithValue, dispatch, extra, getState }) => {
    try {
      const dataToCreateNewWithdrawal = getDataToCreateNewPaymentGatewayWithdrawal(getState());

      const data = {
        amount: dataToCreateNewWithdrawal?.amount,
        currency: dataToCreateNewWithdrawal?.currency,
        bank_account_number: dataToCreateNewWithdrawal?.bankAccount,
        bank_name: dataToCreateNewWithdrawal?.bankName,
        name: dataToCreateNewWithdrawal?.receiptName,
        surname: dataToCreateNewWithdrawal?.receiptSurname,
        organisation_name: dataToCreateNewWithdrawal?.organizationName,
        phone: dataToCreateNewWithdrawal?.phone,
        ifsc_number: dataToCreateNewWithdrawal?.ifscCode ?? ''
      };

      const response = await extra.apiWithAuth.post<NewPaymentGatewayWithdrawalResponse>(
        'fiat/v1/withdrawal',
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (e) {
      const error = e as MyAxiosError<NewPaymentGatewayWithdrawalError>;

      const errorMessage = error.response?.data.error ?? error.response?.data.message ?? 'Server error';

      dispatch(
        alertActions.setAlert({
          id: crypto.randomUUID(),
          message: errorMessage,
          timeout: 5000,
          type: AlertType.ERROR
        })
      );

      return rejectWithValue(e as MyAxiosError<NewPaymentGatewayWithdrawalError>);
    }
  }
);
