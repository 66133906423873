import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { AlertType, alertActions } from 'entities/Alert';
import {
  ExchangeEstimateError,
  NewExchangeEstimateResponse
} from '../../types/newExchangeEstimate/newExchangeEstimate';
import { getDataToCreateNewExchange } from '../../selectors/newExchange/newExchange';
import { CRYPTO_VALUES_TO_DIVIDE_WITHDRAWAL } from 'shared/constants/crypto/crypto-names';
import BigNumber from 'bignumber.js';
import { newExchangeEstimateActions } from '../../slices/newExchangeEstimate/newExchangeEstimate';
import { EXCHANGE_ESTIMATE_CODE_ERRORS } from 'features/NewExchangeForm/lib/errors/exchangeEstimate/exchangeEstimate';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getNewExchangeEstimate = createAsyncThunk<
  NewExchangeEstimateResponse,
  undefined,
  ThunkConfig<MyAxiosError<ExchangeEstimateError>>
>('newExchangeEstimate/getNewExchangeEstimate', async (_, { rejectWithValue, dispatch, extra, getState }) => {
  dispatch(newExchangeEstimateActions.resetError());
  try {
    const dataToNewExchangeEstimate = getDataToCreateNewExchange(getState());

    const correctDestination = dataToNewExchangeEstimate?.destination?.map(({ amount, code, behavior, ...other }) => {
      const amountValue = new BigNumber(amount?.replace(',', '.') || '');
      if (behavior === 'exchange_amount_calc_behavior_direct') {
        const MULTIPLIER = CRYPTO_VALUES_TO_DIVIDE_WITHDRAWAL[dataToNewExchangeEstimate.code?.toUpperCase() || ''];
        return {
          ...other,
          amount: amountValue.multipliedBy(MULTIPLIER).toString(),
          code,
          behavior
        };
      }
      if (behavior === 'exchange_amount_calc_behavior_reverse') {
        const MULTIPLIER = CRYPTO_VALUES_TO_DIVIDE_WITHDRAWAL[code?.toUpperCase() || ''];
        return {
          ...other,
          amount: amountValue.multipliedBy(MULTIPLIER).toString(),
          code,
          behavior
        };
      }

      return { amount, code, behavior, ...other };
    });

    const response = await extra.apiWithAuth.post<NewExchangeEstimateResponse>(
      '/v1/exchange/estimate',
      JSON.stringify({
        fromAddress: dataToNewExchangeEstimate?.fromAddress,
        code: dataToNewExchangeEstimate?.code,
        destination: correctDestination
      }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    dispatch(newExchangeEstimateActions.setResetData());
    const error = e as MyAxiosError<ResponseError>;

    console.log(error);

    const errorMessage = error.response?.data.message || 'Server error';

    dispatch(
      alertActions.setAlert({
        id: crypto.randomUUID(),
        message: errorMessage,
        timeout: 5000,
        type: AlertType.ERROR
      })
    );
    return rejectWithValue(e as MyAxiosError<ExchangeEstimateError>);
  }
});
