import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { getDataToCreateNewInvoice } from '../selectors/new-invoice';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { CreateNewTransactionResponse, ResponseError } from '../types/new-invoice';
import { CRYPTO_SHORT_NAMES, CRYPTO_VALUES_TO_DIVIDE, CryptoName } from 'shared/constants/crypto/crypto-names';
import BigNumber from 'bignumber.js';
import { alertActions, AlertType } from 'entities/Alert';
import { NEW_INVOICE_FORM_ERROR } from '../../lib/contants/errors/new-invoice-errors';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const createNewInvoice = createAsyncThunk<
  CreateNewTransactionResponse,
  undefined,
  ThunkConfig<ResponseError | undefined>
>('newInvoice/createNewInvoice', async (_, { rejectWithValue, extra, getState, dispatch }) => {
  try {
    const dataToCreateNewInvoice = getDataToCreateNewInvoice(getState());
    const invoiceFlag = getState().newInvoice.dataCurrency;
    const code = dataToCreateNewInvoice.code as CryptoName;
    const multiplier = new BigNumber(CRYPTO_VALUES_TO_DIVIDE[code.toLocaleUpperCase() as CRYPTO_SHORT_NAMES]);
    const amount = new BigNumber(dataToCreateNewInvoice.amount!.replace(',', '.')).times(multiplier).toFixed();

    const dataCrypto = {
      txId: dataToCreateNewInvoice.txId,
      code: dataToCreateNewInvoice.code,
      amount,
      type: 'invoice_type_default',
      comment: dataToCreateNewInvoice.comment
    };

    const dataFiat = {
      txId: dataToCreateNewInvoice.txId,
      code: dataToCreateNewInvoice.code,
      fiat: {
        amount: Number(dataToCreateNewInvoice.amount!.replace(',', '.')),
        currency: 'usd'
      },
      type: 'invoice_type_default',
      comment: dataToCreateNewInvoice.comment
    };

    const data = invoiceFlag ? dataFiat : dataCrypto;
    const response = await extra.apiWithAuth.post<CreateNewTransactionResponse>('/v1/transaction/invoice', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status !== 200) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;

    if (error.response) {
      const errorMessage = NEW_INVOICE_FORM_ERROR[error.response.data.code];

      dispatch(
        alertActions.setAlert({
          id: crypto.randomUUID(),
          message: errorMessage,
          timeout: 5000,
          type: AlertType.ERROR
        })
      );
      return rejectWithValue(error.response.data);
    }

    return rejectWithValue(undefined);
  }
});
